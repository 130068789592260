<template>
  <div
      class="FormDate"
      @keyup.capture="updateValue"
  >
    <input
        v-if="showDay"
        ref="day"
        v-model="day"
        class="FormDate__input FormDate__input--day"
        type="number"
        placeholder="dd"
        name="day"
        @input="updateDay"
        @blur="day = day.padStart(2, 0)">
    <span
        v-if="showDay && showMonth"
        class="FormDate__divider"
    >/</span>
    <input
        v-if="showMonth"
        ref="month"
        v-model="month"
        class="FormDate__input FormDate__input--month"
        type="number"
        name="month"
        placeholder="mm"
        @input="updateMonth"
        @blur="month = month.padStart(2, 0)">
    <span
        v-if="showYear && (showDay || showMonth)"
        class="FormDate__divider"
    >/</span>
    <input
        v-if="showYear"
        ref="year"
        name="year"
        v-model="year"
        class="FormDate__input FormDate__input--year"
        type="number"
        placeholder="yyyy"
        @blur="year = year.padStart(4, 0)">
  </div>
</template>

<script>
export default {
  name: `birthday`,
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    showDay: {
      type: Boolean,
      default: true,
    },
    showMonth: {
      type: Boolean,
      default: true,
    },
    showYear: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      day: `${this.value ? new Date(this.value).getDate() : ``}`,
      month: `${this.value ? new Date(this.value).getMonth() + 1 : ``}`,
      year: `${this.value ? new Date(this.value).getFullYear(): ``}`,
    };
  },
  watch: {
    year(current, prev) {
      if (current > 9999) this.year = prev;
    },
  },
  methods: {
    updateDay() {
      if (!this.day.length || parseInt(this.day, 10) < 4) return;
      if (this.showMonth) this.$refs.month.select();
      else if (this.showYear) this.$refs.year.select();
    },
    updateMonth() {
      if (!this.month.length || parseInt(this.month, 10) < 2) return;
      if (this.showYear) this.$refs.year.select();
    },
    updateValue() {
      const timestamp = Date.parse(`${this.year.padStart(4, 0)}-${this.month}-${this.day}`);

      if (Number.isNaN(timestamp)) return;
      this.$emit(`update:value`, timestamp);
    },
  },
};
</script>

<style lang="scss">
.FormDate {
  $spacing: 0.25em;

  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: 0.25em;


  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);


  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    padding: $spacing;
    padding-right: $spacing / 2;
    padding-left: $spacing / 2;
    border: none;
    text-align: center;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $spacing;
    }

    &:last-child {
      padding-right: $spacing;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 3em;
    }

    &--year {
      width: 4em;
    }
  }

  &__divider {
    padding-top: 0px;
    padding-bottom: 0px;
    pointer-events: none;
  }
}
</style>