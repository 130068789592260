export function randomString(length = 7) {
  const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let text = "";

  for (let i = 0; i < length; i++)
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length)
    );

  return text;
}
export function baseUrl() {
  let baseUrl = "";
  if (process.env.NODE_ENV === "production") {
    baseUrl = "https://losgehts.fyss.com/api/";
  } else {
    baseUrl = "http://localhost:8000/api/";
  }
  return baseUrl;
}
