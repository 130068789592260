<template>
  <div>
    <base-header type="main" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <order-list title="Order List"></order-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderList from "./Lists/OrderList";
export default {
  name: "orders",
  components: {
    OrderList,
  },
};
</script>
<style></style>
