<template>
  <div>
    <transition-group name="fade" tag="div">
      <img :src="currentImg" />
    </transition-group>
    <a class="prev" @click.prevent="prev">&#10094;&#10094;&#10094; </a>
    <a class="next" @click.prevent="next">&#10095;&#10095;&#10095; </a>
  </div>
</template>
<script>
import mobile from "../assets/mobile.jpg";
import sunglasses from "../assets/sunglasses.jpg";

export default {
  name: "Carousal",
  data() {
    return {
      images: [mobile, sunglasses],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function () {
    this.startSlide();
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 6000);
    },

    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

img {
  height: 300px;
  width: 100%;
}

.prev,
.next {
  cursor: pointer;
  position: relative;
  top: -50%;
  width: auto;
  padding: 4px;
  color: rgb(216, 170, 19);
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  left: 77%;
}

.prev {
  left: 5px;
}

@media only screen and (max-device-width: 1024px) {
  .next {
    left: 72%;
  }
}
@media only screen and (max-device-width: 768px) {
  .next {
    left: 76%;
  }
}
@media only screen and (max-device-width: 480px) {
  .next {
    left: 75%;
  }
}
@media only screen and (max-device-width: 400px) {
  .next {
    left: 72%;
  }
}
@media only screen and (max-device-width: 320px) {
  .next {
    left: 66%;
  }
}
@media only screen and (max-device-width: 300px) {
  .next {
    left: 64%;
  }
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.carousel {
  width: 640px;
  height: 360px;
}
</style>
