<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script></script>
<style>
@font-face {
  font-family: "SangBleuSunrise";
  src: local("SangBleuSunrise"),
    url(./assets/fonts/SangBleuSunrise-RegularItalic.otf);
  font-family: "Armin-Grotesk";
  src: local("Armin-Grotesk"), url(./assets/fonts/Armin-Grotesk.otf);
}
html,
body {
  font-family: "Armin-Grotesk" !important;
}
html,
body i {
  font-family: "SangBleuSunrise" !important;
}
h2 {
  font-size: 25px !important;
}
.row {
  margin-left: unset !important;
  margin-right: unset !important;
}
.container {
  padding-left: unset !important;
  padding-right: unset !important;
}
.card-body {
  padding: unset !important;
}
</style>
