<template>
  <div>
    <base-header type="main" class="pb-6 pb-8 pt-5 pt-md-8"> </base-header>
    <div class="container">
      <div class="row my-4">
        <div class="col-3">
          <strong>Bestellnummer : </strong><span>{{ this.order_no }}</span>
        </div>
        <div class="col-3">
          <strong>Status : </strong> <span>{{ this.status }}</span>
        </div>
        <div class="col-3">
          <strong>Start Date: </strong> <span>{{ this.startDate }}</span>
        </div>
        <div class="col-3">
          <strong>End Date: </strong> <span>{{ this.completeDate }}</span>
        </div>
      </div>
      <!-- Tables -->
      <div class="row">
        <!-- Customer Information -->
        <div class="col-12 card-header">
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="table-dark text-center">
                <tr>
                  <th colspan="4">Kundeninformation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>E-Mail Adresse</th>
                  <td>{{ this.email }}</td>
                  <th>Geburtstag</th>
                  <td>{{ this.birthday }}</td>
                </tr>
                <tr>
                  <th>Geschlecht</th>
                  <td>{{ this.gender }}</td>
                  <th>Schuhgröße</th>
                  <td>{{ this.shoeSize }}</td>
                </tr>
                <tr>
                  <th>Körpergröße</th>
                  <td>{{ this.height }}</td>
                  <th>Gewicht</th>
                  <td>{{ this.weight }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Medical Questions Information -->
        <div class="col-12 card-header">
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="table-dark text-center">
                <tr>
                  <th colspan="4">Vorerkrankungen</th>
                </tr>
              </thead>
              <tbody class="text-light">
                <tr>
                  <th v-bind:class = "(this.medicalQuestions.leftFersenporn)?'text-dark':''">Fersenporn links</th>
                  <td v-bind:class = "(this.medicalQuestions.leftFersenporn)?'text-dark':''">{{ this.medicalQuestions.leftFersenporn }}</td>
                  <th v-bind:class = "(this.medicalQuestions.rightFersenporn)?'text-dark':''">Fersenporn rechts</th>
                  <td v-bind:class = "(this.medicalQuestions.rightFersenporn)?'text-dark':''">{{ this.medicalQuestions.rightFersenporn }}</td>
                </tr>
                <tr>
                  <th v-bind:class = "(this.medicalQuestions.leftValgus)?'text-dark':''">Valgus links</th>
                  <td v-bind:class = "(this.medicalQuestions.leftValgus)?'text-dark':''">{{ this.medicalQuestions.leftValgus }}</td>
                  <th v-bind:class = "(this.medicalQuestions.rightValgus)?'text-dark':''">Valgus rechts</th>
                  <td v-bind:class = "(this.medicalQuestions.rightValgus)?'text-dark':''">{{ this.medicalQuestions.rightValgus }}</td>
                </tr>
                <tr>
                  <th v-bind:class = "(this.medicalQuestions.diabetic)?'text-dark':''">Diabetiker</th>
                  <td v-bind:class = "(this.medicalQuestions.diabetic)?'text-dark':''">{{ this.medicalQuestions.diabetic }}</td>
                  <th v-bind:class = "(this.medicalQuestions.legLengthImg)?'text-dark':''">Beinlängendifferenz</th>
                  <td>
                    <div v-if="this.medicalQuestions.legLengthImg">
                      <img class="table-image" @click.prevent="showMedicalImage(this.medicalQuestions.legLengthImg)" :src="this.mediaURL + 'Medical Image/' +this.medicalQuestions.legLengthImg" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th v-bind:class = "(this.medicalQuestions.notes)?'text-dark':''">Notes</th>
                  <td colspan="2" v-bind:class = "(this.medicalQuestions.notes)?'text-dark':''">{{ this.medicalQuestions.notes }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <!-- Insole Images-->
        <div class="table-responsive mt-2">
          <table class="table align-items-center table-flush">
            <thead class="thead-dark white-th">
              <tr>
                <th>Category</th>
                <th>Size</th>
                <th>Notes</th>
                <th>Images</th>
              </tr>
            </thead>

            <tr v-for="insole in insoles" :key="insole.id">
              <td>
                {{ insole.category }}
              </td>
              <td>
                {{ insole.insole_size }}
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <span class="completion mr-2"
                    >{{ insole.insole_notes }}
                  </span>
                </div>
              </td>

              <td>
                <div
                  v-for="(image, key) in insole.insole_images"
                  :key="image.insole_image"
                >
                  <img @click.prevent="showModal(image.insole_image)"  class="table-image"
                          :src="this.mediaURL + 'Insole Images/' + image.insole_image"
                          v-bind:ref="'image' + parseInt(key)"/>


                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      
  
        <modal :show="this.showImage">
          <div style="text-align:right">
            <span class="crosssign" @click="close">
              <div class="crosssign_circle"></div>
              <div class="crosssign_stem"></div>
              <div class="crosssign_stem2"></div>
            </span>
            </div>
            <div class="modal-body">
            <img :src="this.imageUrl"/>
          </div>
        </modal>
      <!-- Poses Images-->
      <div class="row my-2">
        <div class="col-6">
          <h3 class="text-center">Fußfoto 1</h3>
          <img @click.prevent="showFootPoses(poseOneImage)" :src="this.mediaURL + 'Foot Poses/' + poseOneImage"/>
        </div>

        <div class="col-6">
          <h3 class="text-center">Fußfoto 2</h3>
          <img @click.prevent="showFootPoses(poseThreeImage)" :src="this.mediaURL + 'Foot Poses/' + poseThreeImage" />
        </div>
        <div class="col-12 my-2 text-center">
          <h3 class="text-center">GangVideo</h3>
          <figure class="media" style="margin:auto !important;">
            <video
              preload="metadata"
              controls
              playsinline
              :src="this.mediaURL + 'Foot Poses/' + cat_walk_video"
              :type="this.video_type"
              >
            </video>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

axios.defaults.baseURL =process.env.VUE_APP_API_ENDPOINT;
export default {
  name: "orders",
  data() {
    return {
      imageUrl : "",
      showImage :false,
      mediaURL:process.env.VUE_APP_MEDIA_ENDPOINT,
      order_no: "",
      orderId: "",
      completeDate: "",
      startDate: "",
      status: "",
      email: "",
      birthday: "",
      gender: "",
      weight: "",
      height: "",
      shoeSize: "",
      poseOneImage: "",
      poseTwoImage: "",
      poseThreeImage: "",
      cat_walk_video: "",
      video_type: "",
      fullWidthImage: false,
      insoles: "",
      insoleImages: "",
      medicalQuestions: {
        rightFersenporn: "",
        leftFersenporn: "",
        rightValgus: "",
        leftValgus: "",
        diabetic: "",
        legLengthImg: "",
        notes: "",
      },
      fussOben: {
        leftZehen: "",
        leftOberfuss: "",
        leftAussenKnoechel: "",
        leftInnenKnoechel: "",

        rightZehen: "",
        rightOberfuss: "",
        rightAussenKnoechel: "",
        rightInnenKnoechel: "",
        fussObenNotes: "",
      },
      fussUnten: {
        leftZehen: "",
        leftkleinerBallen: "",
        leftGrosserBallen: "",
        leftAussenRist: "",
        leftInnenRist: "",
        leftFerse: "",

        rightZehen: "",
        rightkleinerBallen: "",
        rightGrosserBallen: "",
        rightAussenRist: "",
        rightInnenRist: "",
        rightFerse: "",
        fussUntenNotes: "",
      },
      koerper: {
        linkerFuss: "",
        linkerUnterschenkel: "",
        linkesKnie: "",
        linkerOberschenkel: "",
        linkerArm: "",

        rechterFuss: "",
        rechterUnterschenkel: "",
        rechtesKnie: "",
        rechterOberschenkel: "",
        rechterArm: "",
        koerperNotes: "",
        huefteBecken: "",
        brustkorb: "",
        kopf: "",
      },
    };
  },
  mounted() {
    axios
      .get("order-detail/" + this.$route.params.order_id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage["token"],
        },
      })
      .then((response) => {
        if (response.data.data) {
          this.order_no = response.data.data.order_number;
          this.orderId = response.data.data.id;
          this.completeDate = response.data.data.completeDate;
          this.startDate = response.data.data.startDate;
          this.status = response.data.data.status;
          this.email = response.data.data.customer.email;
          this.birthday = response.data.data.customer.birthday;
          this.gender = response.data.data.customer.gender;
          this.height = response.data.data.customer.height;
          this.weight = response.data.data.customer.weight;
          this.shoeSize = response.data.data.customer.shoeSize;
          //Medical Information
          if (response.data.data.customer.medicalQuestions) {
            this.medicalQuestions.rightFersenporn =
              response.data.data.customer.medicalQuestions.rightFersenporn;
            this.medicalQuestions.leftFersenporn =
              response.data.data.customer.medicalQuestions.leftFersenporn;
            this.medicalQuestions.rightValgus =
              response.data.data.customer.medicalQuestions.rightValgus;
            this.medicalQuestions.leftValgus =
              response.data.data.customer.medicalQuestions.leftValgus;
            this.medicalQuestions.diabetic =
              response.data.data.customer.medicalQuestions.diabetic;
            this.medicalQuestions.legLengthImg =
              response.data.data.customer.medicalQuestions.legLengthImg;
            this.medicalQuestions.notes =
              response.data.data.customer.medicalQuestions.notes;
          }
          // Foot Pain Points Above
          if (response.data.data.customer.footPainPointsAbove) {
            this.fussOben.leftZehen =
              response.data.data.customer.footPainPointsAbove.leftZehen;
            this.fussOben.leftOberfuss =
              response.data.data.customer.footPainPointsAbove.leftOberfuss;
            this.fussOben.leftAussenKnoechel =
              response.data.data.customer.footPainPointsAbove.leftAussenKnoechel;
            this.fussOben.leftInnenKnoechel =
              response.data.data.customer.footPainPointsAbove.leftInnenKnoechel;

            this.fussOben.rightZehen =
              response.data.data.customer.footPainPointsAbove.rightZehen;
            this.fussOben.rightOberfuss =
              response.data.data.customer.footPainPointsAbove.rightOberfuss;
            this.fussOben.rightAussenKnoechel =
              response.data.data.customer.footPainPointsAbove.rightAussenKnoechel;
            this.fussOben.rightInnenKnoechel =
              response.data.data.customer.footPainPointsAbove.rightInnenKnoechel;
            this.fussOben.fussObenNotes =
              response.data.data.customer.footPainPointsAbove.fussObenNotes;
          }
          // Foot Pain Points Below
          if (response.data.data.customer.footPainPointsBelow) {
            this.fussUnten.leftZehen =
              response.data.data.customer.footPainPointsBelow.leftZehen;
            this.fussUnten.leftkleinerBallen =
              response.data.data.customer.footPainPointsBelow.leftkleinerBallen;
            this.fussUnten.leftGrosserBallen =
              response.data.data.customer.footPainPointsBelow.leftGrosserBallen;
            this.fussUnten.leftAussenRist =
              response.data.data.customer.footPainPointsBelow.leftAussenRist;
            this.fussUnten.leftInnenRist =
              response.data.data.customer.footPainPointsBelow.leftInnenRist;
            this.fussUnten.leftFerse =
              response.data.data.customer.footPainPointsBelow.leftFerse;

            this.fussUnten.rightZehen =
              response.data.data.customer.footPainPointsBelow.rightZehen;
            this.fussUnten.rightkleinerBallen =
              response.data.data.customer.footPainPointsBelow.rightkleinerBallen;
            this.fussUnten.rightGrosserBallen =
              response.data.data.customer.footPainPointsBelow.rightGrosserBallen;
            this.fussUnten.rightAussenRist =
              response.data.data.customer.footPainPointsBelow.rightAussenRist;
            this.fussUnten.rightInnenRist =
              response.data.data.customer.footPainPointsBelow.rightInnenRist;
            this.fussUnten.rightFerse =
              response.data.data.customer.footPainPointsBelow.rightFerse;
            this.fussUnten.fussUntenNotes =
              response.data.data.customer.footPainPointsBelow.fussUntenNotes;
          }
          // Body Pain Points
          if (response.data.data.customer.bodyPainPoints) {
            this.koerper.huefteBecken =
              response.data.data.customer.bodyPainPoints.huefteBecken;
            this.koerper.brustkorb =
              response.data.data.customer.bodyPainPoints.brustkorb;
            this.koerper.kopf =
              response.data.data.customer.bodyPainPoints.kopf;
            this.koerper.koerperNotes =
              response.data.data.customer.bodyPainPoints.koerperNotes;

            this.koerper.linkerFuss =
              response.data.data.customer.bodyPainPoints.linkerFuss;
            this.koerper.linkerUnterschenkel =
              response.data.data.customer.bodyPainPoints.linkerUnterschenkel;
            this.koerper.linkesKnie =
              response.data.data.customer.bodyPainPoints.linkesKnie;
            this.koerper.linkerOberschenkel =
              response.data.data.customer.bodyPainPoints.linkerOberschenkel;
            this.koerper.linkerArm =
              response.data.data.customer.bodyPainPoints.linkerArm;

            this.koerper.rechterFuss =
              response.data.data.customer.bodyPainPoints.rechterFuss;
            this.koerper.rechterUnterschenkel =
              response.data.data.customer.bodyPainPoints.rechterUnterschenkel;
            this.koerper.rechtesKnie =
              response.data.data.customer.bodyPainPoints.rechtesKnie;
            this.koerper.rechterOberschenkel =
              response.data.data.customer.bodyPainPoints.rechterOberschenkel;
            this.koerper.rechterArm =
              response.data.data.customer.bodyPainPoints.rechterArm;
          }

          //Poses Images
          this.poseOneImage =
            response.data.data.customer.footImages.poseOneImage;
          this.poseTwoImage =
            response.data.data.customer.footImages.poseTwoImage;
          this.poseThreeImage =
            response.data.data.customer.footImages.poseThreeImage;
          this.cat_walk_video =
            response.data.data.customer.footImages.catwalkVideo;
          this.video_type =
            response.data.data.customer.footImages.videoType;

          this.insoles = response.data.data.insoles;
          this.insoleImages = response.data.data.insoles.insoleImages;

          window.scrollTo(0, 0);
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.$router.push("login");
        throw error;
      });
  },
  methods:{
    showModal:function(image)
    {
      this.imageUrl = this.mediaURL + 'Insole Images/' +image;
      this.showImage = true;
    },
    showMedicalImage:function(image)
    {
      this.imageUrl = this.mediaURL + 'Medical Image/' +image;
      this.showImage = true;
    },
    showFootPoses:function(image)
    {
      this.imageUrl = this.mediaURL + 'Foot Poses/' +image;
      this.showImage = true;
    },
    close() {
      this.showImage = false;
    },
  }
};
</script>
<style>
.full {
  width: 100%;
  height: auto;
}

img:hover {
  cursor: pointer;
}
.white-th tr th {
  color: white !important;
}
.table-image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  padding: 5px;
  float: left;
}
.crosssign {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
  transform: rotate(45deg);
  cursor: pointer;
}

.crosssign_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: red;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.crosssign_stem,
.crosssign_stem2 {
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.crosssign_stem {
  width: 3px;
  height: 9px;
}

.crosssign_stem2 {
  width: 9px;
  height: 3px;
}
/* .modal-body {
  padding: 0.5rem !important ;
}
.modal-content{
  box-shadow: unset !important;
  background-color: transparent !important;
} */
</style>
