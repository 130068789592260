<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-3">
          <input type="text" v-model="keyword" class="form-control">
        </div>
          <div class="col-2">
          <base-button type="primary" size="sm" @click.prevent="search">Search</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="list"
      >
        <template v-slot:columns>
          <th>Email</th>
          <th>Order Number</th>
          <th>Status</th>
          <th>Order Count</th>
          <th>Action</th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="align-items-center">
              <span class="name mb-0 text-sm">{{
                row.item.customer.email
              }}</span>
            </div>
          </th>
          <td class="budget">
            {{ row.item.order_number }}
          </td>
          <td>
            <badge class="badge-dot mr-4">
              <span class="status">{{ row.item.status }}</span>
            </badge>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <span class="completion mr-2"
                >{{ row.item.customer.counter }}
              </span>
            </div>
          </td>

          <td class="text-left">
            <router-link
              class="btn btn-xs btn-success detail-btn"
              :to="{ name: 'order-detail', params: { order_id: row.item.id } }"
              ><img :src="detail" /></router-link
            >
            <button
              class="btn btn-xs btn-danger detail-btn" @click.prevent="removeRow(row.item,row.item.id)"
              ><img :src="hide" /></button
            >
          </td>
        </template>
      </base-table>
    </div>

    <div class="row">
      <div class="col-md-4">
        <select v-model="perPage" @change="onPerPageChange" class="mt-4 border p-2">
          <option value="20" :class="this.perPage==20?'Selected':''">20</option>
          <option value="30" :class="this.perPage==30?'Selected':''">30</option>
          <option value="50" :class="this.perPage==50?'Selected':''">50</option>
          <option value="100" :class="this.perPage==100?'Selected':''">100</option>
        </select>
      </div>
      <div class="col-md-8">
        <div class="card-footer d-flex justify-content-end">
          <base-pagination :pageCount="this.totalPages" :perPage="this.perPage" :total="this.totalItems" :value="this.currentPage" @pagechanged="onPageChange"></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import hide from "../../assets/hide.png";
import detail from "../../assets/view-details.png";

axios.defaults.baseURL =process.env.VUE_APP_API_ENDPOINT;
export default {
  name: "order-list",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      hide: hide,
      detail:detail,
      list: "",
      perPageValue:"",
      keyword:'',
      totalItems : "",
      totalPages : "",
      perPage:20,
      currentPage:1
    };
  },
  created() {
    this.getOrders(this.currentPage,this.perPage);
  },
 
  methods:{
   removeRow: function(index,id) {
    var result = confirm("Want to delete?");
    if (result) { 
      var formData = new FormData();
          formData.append("id",id);
          axios
            .post("delete", formData, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage["token"],
              },
            })
            .then((response) => {
              if (response.data) {
                console.log(response.data.deleted);
                window.location.reload();
              }
            })
            .catch((error) => {
              //window.scrollTo(0, 100);
              throw error;
            });
    }
  },
    getOrders(currentPage,perPage){
      axios
      .get(`order-list/?page=${currentPage}&per_page=${perPage}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage["token"],
        },
      })
      .then((response) => {
        if (response.data) {
          this.list = response.data.data;
          this.totalItems = parseInt(response.data.paginator.totalItems);
          this.totalPages = parseInt(response.data.paginator.totalPages);
          this.currentPage = parseInt(response.data.paginator.currentPage);
          this.perPage = parseInt(response.data.paginator.perPage);
          window.scrollTo(0, 0);
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.$router.push("login");
        throw error;
      });
    },
    
    onPageChange(page) {
      this.currentPage = page;
      this.getOrders(this.currentPage,this.perPage);
		},
    onPerPageChange() {
      this.perPage = parseInt(this.perPage);
      this.getOrders(this.currentPage,this.perPage);
		},
    search(){
      axios
      .get("search/?keyword="+this.keyword, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage["token"],
        },
      })
      .then((response) => {
        if (response.data) {
          this.list = response.data.data;
          window.scrollTo(0, 0);
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.$router.push("login");
        throw error;
      });
    }
  }
};
</script>
<style>
.detail-btn {
  line-height: 0.5 !important;
  padding: 0.65rem !important;
  font-size: small !important;
  font-weight: 400 !important;
}
</style>
