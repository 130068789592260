<template>
  <div>
    <transition-group name="fade" tag="div">
      <img :src="currentImg" />
    </transition-group>
    <a class="prev" @click.prevent="prev">&#10094; </a>
    <a class="next" @click.prevent="next">&#10095; </a>
  </div>
</template>
<script>
import p7 from "../assets/images/p7.jpg";
import p8 from "../assets/images/p8.jpg";
import p9 from "../assets/images/p9.jpg";
import p10 from "../assets/images/p10.jpg";
import p11 from "../assets/images/p11.jpg";
import p12_1 from "../assets/images/p12-1.jpg";
import p12_2 from "../assets/images/p12-2.jpg";

export default {
  name: "Slider",
  data() {
    return {
      images: [p7, p8, p9, p10, p11, p12_1, p12_2],
      timer: null,
      currentIndex: 0,
      activeImage: 0,
    };
  },

  mounted: function () {
    // this.startSlide();
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 6000);
    },

    next: function () {
      var active = this.activeImage + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    prev: function () {
      var active = this.activeImage - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
  },

  computed: {
    currentImg: function () {
      return this.images[this.activeImage];
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

img {
  height: auto;
  width: 100%;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 42%;
  width: auto;
  padding: 6px;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  text-decoration: none;
  user-select: none;
  background-color: white;
  border-radius: 31px;
  opacity: 1;
  color: #2f2f2f;
}

.next {
  left: 90%;
}

.prev {
  left: 21px;
}

@media only screen and (max-device-width: 1024px) {
  .next {
    left: 88%;
  }
}
@media only screen and (max-device-width: 768px) {
  .next {
    left: 92%;
  }
}
@media only screen and (max-device-width: 480px) {
  .next {
    left: 89%;
  }
}
@media only screen and (max-device-width: 400px) {
  .next {
    left: 88%;
  }
}
@media only screen and (max-device-width: 320px) {
  .next {
    left: 85%;
  }
}
@media only screen and (max-device-width: 300px) {
  .next {
    left: 83%;
  }
}

.carousel {
  width: 640px;
  height: 360px;
}
</style>
