<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid align-items-center">
        <div class="row">
          <div class="col-lg-7">
            <h1 class="display-2 text-white">Hello {{this.model.username}}</h1>
            <p class="text-white mt-0 mb-3">
              This is your profile page. You can update your profile from here.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">My account</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Username"
                      placeholder="Username"
                      input-classes="form-control-alternative"
                      v-model="model.username"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      type="email"
                      label="Email address"
                      placeholder="jesse@example.com"
                      input-classes="form-control-alternative"
                      v-model="model.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      type="password"
                      label="Password"
                      placeholder="Write new password if you want to update"
                      input-classes="form-control-alternative"
                      v-model="model.password"
                    />
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-lg-4">
                    <button class="btn btn-info" @click.prevent="updateProfile">Update profile</button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

axios.defaults.baseURL =process.env.VUE_APP_API_ENDPOINT;
export default {
  name: "user-profile",
  data() {
    return {
      model: {
        id: "",
        username: "",
        email: "",
        password:'',
      },
    };
  },
  mounted(){
    axios
      .get("profile/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage["token"],
        },
      })
      .then((response) => {
        if (response.data) {
          this.model.email = response.data.email;
          this.model.username = response.data.name;
          this.model.id = response.data.id;
          window.scrollTo(0, 0);
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.$router.push("login");
        throw error;
      });
  },
  methods:{
      updateProfile: function (){
       var formData = new FormData();
       formData.append('name',this.model.username);
       formData.append('email',this.model.email);
       formData.append('password',this.model.password);
       formData.append('id',this.model.id);
        axios
      .post("update-profile/", formData,{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage["token"],
        },
      })
      .then((response) => {
        if (response.data) {
          this.model.email = response.data.data.email;
          this.model.username = response.data.data.name;
          window.scrollTo(0, 0);
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.$router.push("login");
        throw error;
      });
      }
  }
};
</script>
<style></style>
