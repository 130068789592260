<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7 mt-3">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-2">
          <div class="text-center mt-2 mb-2">
            <h2>Login</h2>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-3">
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              v-model="model.email"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              v-model="model.password"
            >
            </base-input>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click.prevent="login"
                >Sign in</base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      var loginData = new FormData();
      loginData.append("email", this.model.email);
      loginData.append("password", this.model.password);
      axios
        .post("login", loginData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.access_token) {
            localStorage["token"] = response.data.access_token;
            window.location.href = "#/orders";
          } else {
            return false;
          }
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
</script>
<style></style>
