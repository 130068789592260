<template>
  <div class="main-content bg-main">
    <!-- Navbar -->
    <!-- <div class="languageSwitcher">
      <a v-if="$i18n.locale !== 'de'" v-on:click="changeLanguage('de')">DE</a>
      <strong v-if="$i18n.locale === 'de'">DE</strong>
      &nbsp;|&nbsp;
      <a v-if="$i18n.locale !== 'en'" v-on:click="changeLanguage('en')">EN</a>
      <strong v-if="$i18n.locale === 'en'">EN</strong>
    </div> -->
    <!-- Navbar -->
    <div class="row py-3 logo-row">
      <div class="logo">
        <a href="/">
          <img src="../assets/logo.svg" />
        </a>
      </div>
    </div>
    <!-- Header -->

    <!-- Page content -->
    <div class="container pb-5" style="min-height: calc(100vh - 194px)">
      <router-view></router-view>
    </div>
    <footer class="py-5 logo-row">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="logo">
            <img src="../assets/logo.svg" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style>
.languageSwitcher {
  color: #fff;
  margin-left: 10px;
  padding: 10px;
}
.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.logo-row {
  background-color: #2f2f2f !important;
}
.bg-main {
  background-color: #84b2b9;
}
</style>
